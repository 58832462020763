<template>
  <div class="save-wrapper">
    <b-row v-for="item in data" :key="item.id" class="box-shadow border-rad-15 mt-4 save-row">
      <b-col md="3"><img :src="share" /> <img class="cursor-pointer" @click="removeComment(item.id, name)" :src="saved" /></b-col>
      <b-col v-if="name == 'Plans'" class="text-right font-sfns fs-14" md="9"><strong><router-link :to="{name: 'city', params: {cityId: item.city_id} ,query:{plan: item.planNo}}">{{ item.planNo }}</router-link></strong></b-col>
      <b-col class="text-right font-sfns fs-14" v-if="name == 'Plots'" md="9"><strong>{{ item.planNo }}</strong></b-col>
      <b-col v-if="name == 'Plans'" cols="3" class="mt-4"><b-badge class="info-badge min" pill variant="light"><span class="font-sfns">{{ item.plots_count }}</span> <span class="fs-8">عدد</span></b-badge></b-col>
      <b-col v-if="name == 'Plans'" cols="3" class="mt-4"><router-link :to="{name: 'city', params: {cityId: item.city_id} ,query:{plan: item.planNo}}"><b-badge class="info-badge min" pill variant="light"><span class="font-sfns">الموقع</span></b-badge></router-link></b-col>
      <b-col v-if="name == 'Plans'" cols="6" class="mt-4 text-right"><b-badge class="info-badge" pill variant="light"><span class="fs-14">{{ item.district }}</span> <span class="fs-8">المدينة</span></b-badge></b-col>
      <b-col v-if="name == 'Plots'" cols="4" class="mt-4"><b-badge class="info-badge min" pill variant="light"><span class="font-sfns">{{ item.plotNo }}</span> <span class="fs-8">{{ $t('plot_no') }}</span></b-badge></b-col>
      <b-col v-if="name == 'Plots'" cols="3" class="mt-4"><router-link :to="{name: 'plot', params:{plotId: item.plotNo}, query:{plan: item.planNo, meter_sq: item.meter_sq, coords: item.plot_points, city_id: item.city_id}}"><b-badge class="info-badge min" pill variant="light"><span class="font-sfns">الموقع</span></b-badge></router-link></b-col>
      <b-col v-if="name == 'Plots'" cols="5" class="mt-4 text-right"><b-badge class="info-badge" pill variant="light"><span class="fs-14">{{ numberFormat(item.meter_sq) }}</span> <span class="fs-8">{{ $t('meter_sq') }}</span></b-badge></b-col>
      <b-col class="comment mt-4 border-rad-15" cols="12">
        <p class="title">تعليق</p>
        <p>{{ item.comment }}</p>
      </b-col>
    </b-row>
    <p v-if="!data.length" class="text-center mtop-150">
      <img :src="save" width="100px" />
      <span class="d-block mt-4 font-sfns">{{ this.noSaved }}</span>
    </p>
    <v-dialog />
  </div>
</template>

<script>
import utils from '@/helper/utils.js'

export default {
  name: 'Saves',
  props: {
    data: {
      type: Array,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      map: require("@/assets/images/icons/map_box.png"),
      share: require("@/assets/images/icons/share_.svg"),
      saved: require("@/assets/images/icons/saved.svg"),
      save: require("@/assets/images/icons/save.svg"),
      width: "28px"
    }
  },
  computed: {
    noSaved() {
      if (this.name === "Plans")
        return "لا يوجد مخطط مفضل"
      return "لايوجد قطعة مفضلة"
    }
  },
  methods: {
    numberFormat(val) {
      if(val == null) return ""
      return utils.numberFormat(val)
    },
    removeFavouritePlot(id) {
      let loader = this.$loading.show({opacity: 0.9})
      this.$store.dispatch("plan/removeFavouritePlot", id)
      .then(() => { loader.hide() })
      .catch(() => {
        loader.hide()
      })
    },
    removeFavouritePlan(id) {
      let loader = this.$loading.show({opacity: 0.9})
      this.$store.dispatch("plan/removeFavouritePlan", id)
      .then(() => { loader.hide() })
      .catch(() => {
        loader.hide()
      })
    },
    removeComment(id, name) {
      this.$modal.show('dialog', {
        title: "حذف القطعة",
        text: "هل تريد حذف القطعة ؟",
        buttons: [
          {
            title: "الغاء", //cancel
            handler: () => {
              this.$modal.hide('dialog')
            }
          },
          {
            title: "نعم", //yes
            handler: () => {
              if(name === "Plots") {
                this.removeFavouritePlot(id)
              }
              else {
                this.removeFavouritePlan(id)
              }
              this.$modal.hide('dialog')
            }
          }
        ]
      })
    }
  }
}
</script>
