<template>
  <b-container fluid class="user-content">
    <div class="saved">
      <div class="saved-switch">
        <div @click="switchPane" class="switch" :class="{'active': first}">
          المخططات
        </div>
        <div @click="switchPane" :class="{'active': second}" class="switch">
        القِطع
        </div>
      </div>
      <saves v-if="first" name="Plans" :data="plans" />
      <saves v-if="second" name="Plots" :data="plots" />
    </div>
  </b-container>
</template>
<script>
import Saves from '@/components/Saves.vue'

export default {
  title: "المفضلة",
  data() {
    return {
      first: true,
      second: false,
    }
  },
  components: {
    Saves
  },
  watch: {
    first() {
      if (this.first)
        this.favouritePlans()
    },
    second() {
      if (this.second)
        this.favouritePlots()
    }
  },
  computed: {
    plans() {
      return this.$store.state.plan.favourite_plans
      //return this.$store.getters['plan/reverseFavouritePlans']
    },
    plots() {
      return this.$store.state.plan.favourite_plots
      //return this.$store.getters['plan/reverseFavouritePlots']
    }
  },
  methods: {
    favouritePlans() {
      let loader = this.$loading.show({opacity: 0.9})
      this.$store.dispatch("plan/favouritePlans")
      .then(() => {
         loader.hide()
      })
      .catch(() => {
        loader.hide()
      })
    },
    favouritePlots() {
      let loader = this.$loading.show({opacity: 0.9})
      this.$store.dispatch("plan/favouritePlots")
      .then(() => {
         loader.hide()
      })
      .catch(() => {
        loader.hide()
      })

    },
    switchPane(event) {
      event.preventDefault()
      if (event.target.classList.length > 1)
        return
      this.first = !this.first
      this.second = !this.second
    }
  },
  created() {
    this.favouritePlans()
  },
  mounted() {

  }
}
</script>

<style lang="scss">
#map {
  width: 100%;
  height: 100vh;
}
</style>
